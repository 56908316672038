var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 primary white--text text-break"
  }, [_vm._v(" " + _vm._s(_vm.$t('sentryFeedback.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "sendFeedbackForm",
    attrs: {
      "id": "send-feedback-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendFeedback.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-alert', {
    attrs: {
      "type": "info",
      "colored-border": "",
      "border": "left",
      "elevation": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sentryFeedback.prompt')) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.name'),
      "outlined": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.email'),
      "rules": [_vm.isEmail(_vm.email, _vm.$t('form.field.email'))],
      "outlined": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": _vm.$t('form.field.sentryFeedback'),
      "placeholder": _vm.$t('form.placeholder.sentryFeedback'),
      "rules": [_vm.isRequired(_vm.feedback, _vm.$t('form.field.sentryFeedback'))],
      "outlined": ""
    },
    model: {
      value: _vm.feedback,
      callback: function callback($$v) {
        _vm.feedback = $$v;
      },
      expression: "feedback"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "send-feedback-form"
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.sendFeedback')) + " ")]), _c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiSend))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }