
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiSend } from '@mdi/js'

import { User } from '@/api/types/user'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired, isEmail } from '@/utils/validation'

import * as Sentry from '@sentry/vue'
import { handleError } from '@/utils/handleError'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'SentryFeedbackDialog',
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    // Form Ref
    const sendFeedbackForm = ref<ValidationForm | null>(null)

    const { addNotification } = useNotify()

    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    // Input Refs
    const name = ref(currentUser.value ? `${currentUser.value.firstName} ${currentUser.value.lastName}` : '')
    const email = ref(currentUser.value ? currentUser.value.email : '')
    const feedback = ref('')

    const sendFeedback = () => {
      if (sendFeedbackForm.value?.validate()) {
        try {
          const userFeedback = {
            event_id: props.eventId,
            name: name.value,
            email: email.value,
            comments: feedback.value,
          }

          Sentry.captureUserFeedback(userFeedback)

          addNotification({
            text: root.$t('misc.feedback.form.success') as string,
            type: 'success',
            timeout: 3000,
          })

          emit('close')
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    return {
      icons: { mdiSend },
      sendFeedbackForm,
      isRequired,
      name,
      email,
      isEmail,
      feedback,
      sendFeedback,
    }
  },
})
